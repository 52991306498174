import { FunctionComponent } from 'react'
import { Box, Stack, Center, Image, useTheme } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export const PageContainer: FunctionComponent = (props) => {
  const theme = useTheme()

  return (
    <Stack
      marginY={{ base: '3', md: '10' }}
      width={{ base: '100%' }}
      maxWidth={{ base: '100%', md: 'xl' }}
    >
      <Link to="/">
        <Center>
          <Image
            src="/header-logo.svg"
            alt="Book My Friends"
            marginY="3"
            paddingX={{ base: '6', md: '3' }}
          />
        </Center>
      </Link>
      <Box
        background={theme.colors.white}
        borderRadius="xl"
        display="flex"
        padding={{ base: '5', md: '8' }}
        margin={{ md: '4' }}
      >
        {props.children}
      </Box>
    </Stack>
  )
}
