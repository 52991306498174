import { Button, Center, Heading, Image, Stack, Text } from '@chakra-ui/react'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { useHistory } from 'react-router-dom'

export function Home() {
  const history = useHistory()

  return (
    <Stack flex="1" spacing="3">
      <Heading color="gray.700" fontSize="2xl" textAlign="center">
        Finding a date that works for everyone can be painful.
      </Heading>
      <Center>
        <Image src="./home.svg" boxSize="200px" />
      </Center>
      <Text>
        Book My Friends makes this easier by allowing your friends to choose the dates they are
        available.
      </Text>
      <Stack align="center">
        <Button
          marginTop="5"
          colorScheme="blue"
          fontSize="md"
          size="lg"
          type="submit"
          rightIcon={<ArrowForwardIcon />}
          onClick={() => {
            history.push('/create')
          }}
          width={{ base: '100%', md: '15rem' }}
        >
          Get started
        </Button>
      </Stack>
    </Stack>
  )
}
