import { Fragment, useState, useEffect } from 'react'
import { Button, Text, Input, Stack } from '@chakra-ui/react'
import { useParams, useHistory } from 'react-router-dom'
import { Calendar } from 'components/Calendar'
import { Loading } from 'components/Loading'
import { Event, getEvent, createResponse } from 'api'

export function Respond() {
  const [isLoading, setLoading] = useState(true)
  const [isSubmitting, setSubmitting] = useState(false)
  const [event, setEvent] = useState<Event>()
  const [dates, setDates] = useState<string[]>([])
  const [author, setAuthor] = useState('')
  const [isValid, setValid] = useState(false)
  const { id } = useParams<any>()
  const history = useHistory()

  useEffect(() => {
    async function loadEvent() {
      const result = await getEvent(id)
      setEvent(result)
      setLoading(false)
    }
    loadEvent()
  }, [id])

  useEffect(() => {
    setValid(dates.length !== 0 && author !== '')
  }, [dates, author])

  const submitResponse = async (formEvent: React.FormEvent) => {
    if (!event) return
    formEvent.preventDefault()
    try {
      setSubmitting(true)
      await createResponse(event.id, { author, dates })
      // Navigate to responded page.
      history.push({
        pathname: '/responded',
        state: {
          author,
          event,
        },
      })
    } catch (error) {
      setSubmitting(false)
      // TODO: Error handling
    }
  }

  return (
    <Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <form onSubmit={submitResponse}>
          <Stack spacing="4">
            <Text>
              {event!.author} wants to find a time that works for everyone. Enter your name and
              select the dates that work for you.
            </Text>
            <Input
              size="lg"
              fontSize="md"
              placeholder="Name"
              value={author}
              onChange={(event) => {
                setAuthor(event.target.value)
              }}
            />
            {/*<Text fontSize="sm">What dates work for you?</Text>*/}
            <Calendar dates={dates} setDates={setDates} />
            {/*<Input placeholder="Do you have a preferred time? e.g. 9am" size="lg" fontSize="sm" />*/}
            <Button
              type="submit"
              colorScheme="blue"
              isDisabled={!isValid || isSubmitting}
              size="lg"
              fontSize="md"
              isLoading={isSubmitting}
              loadingText="Responding..."
            >
              {dates.length
                ? `Respond with ${dates.length} day${dates.length === 1 ? '' : 's'}`
                : `Respond`}
            </Button>
          </Stack>
        </form>
      )}
    </Fragment>
  )
}
