import { extendTheme } from '@chakra-ui/react'
import colors from './colors'

const overrides = {
  colors,
  fonts: {
    heading: 'Inter',
    body: 'Inter',
  },
}

export default extendTheme(overrides)
