import { FunctionComponent } from 'react'
import { Box } from '@chakra-ui/react'
import { enGB } from 'date-fns/locale'
import { Calendar as ReactCalendar } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'

function dateStringFromDate(date: Date) {
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
  const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date)
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date)
  return `${year}-${month}-${day}`
}

const CalendarContainer: FunctionComponent = (props) => {
  return (
    <Box
      sx={{
        '.nice-dates-day:hover:after': {
          borderColor: 'blue.500',
        },
        '.nice-dates-day.-selected:before': {
          backgroundColor: 'blue.500',
        },
        '.nice-dates-day.-selected:after': {
          backgroundColor: 'blue.600',
        },
        '.nice-dates-day.-selected span': {
          color: 'white',
        },
        // Ugly hack to make things appear smaller
        '.nice-dates-day': {
          transform: 'scale(0.95)',
        },
      }}
    >
      {props.children}
    </Box>
  )
}

// An ugly hack to allow touchDragEnabled to be set...
const UnsafeReactCalendar = ReactCalendar as any

export function Calendar({ dates, setDates }: { dates: String[]; setDates: any }) {
  const modifiers = {
    selected: (date: Date) => dates.includes(dateStringFromDate(date)),
  }

  const handleDayClick = (date: Date | null) => {
    if (!date) {
      return
    }
    const dateString = dateStringFromDate(date)
    if (dates.includes(dateString)) {
      setDates(dates.filter((selectedDate) => selectedDate !== dateString))
    } else {
      setDates([...dates, dateStringFromDate(date)])
    }
  }

  return (
    <CalendarContainer>
      <UnsafeReactCalendar
        onDayClick={handleDayClick}
        modifiers={modifiers}
        locale={enGB}
        touchDragEnabled={false}
      />
    </CalendarContainer>
  )
}
