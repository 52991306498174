import { useLocation } from 'react-router-dom'
import { Stack, Text } from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'

export function Responded() {
  const location = useLocation<any>()

  return (
    <Stack flex="1" spacing="4" align="center">
      <CheckIcon w={20} h={20} color="blue.500" />
      <Text fontWeight="400">
        Thanks {location.state.author}! {location.state.event.author} will be in touch soon with the
        final date.
      </Text>
    </Stack>
  )
}
