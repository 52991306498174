import { ReactElement } from 'react'
import { Box, Stack, Text } from '@chakra-ui/react'

type InfoPanelProps = {
  backgroundColor: string
  color: string
  icon: ReactElement
  text: string
  fontSize?: string
}

export const InfoPanel = (props: InfoPanelProps) => {
  return (
    <Box backgroundColor={props.backgroundColor} color={props.color} padding="4" borderRadius="md">
      <Stack direction="row" align="center" spacing="3">
        {props.icon}
        <Text fontSize={props.fontSize || 'sm'}>{props.text}</Text>
      </Stack>
    </Box>
  )
}
