import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Create, Respond, Responded, View, Home } from 'pages'
import { AppContainer } from 'components/AppContainer'
import { PageContainer } from 'components/PageContainer'

export function App() {
  return (
    <AppContainer>
      <Router>
        <PageContainer>
          <Switch>
            <Route path="/create">
              <Create />
            </Route>
            <Route path="/respond/:id">
              <Respond />
            </Route>
            <Route path="/responded">
              <Responded />
            </Route>
            <Route path="/view/:id">
              <View />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </PageContainer>
      </Router>
    </AppContainer>
  )
}
