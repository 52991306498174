import { FunctionComponent } from 'react'
import { Box } from '@chakra-ui/react'

export const AppContainer: FunctionComponent = (props) => {
  return (
    <Box display="flex" flexDirection="column" flex="1" alignItems="center">
      {props.children}
    </Box>
  )
}
