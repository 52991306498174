const colors = {
  blue: {
    '50': '#E5F4FF',
    '100': '#B8E0FF',
    '200': '#8ACCFF',
    '300': '#5CB8FF',
    '400': '#2EA4FF',
    '500': '#0090FF',
    '600': '#0073CC',
    '700': '#005699',
    '800': '#003A66',
    '900': '#001D33',
  },
}

export default colors
