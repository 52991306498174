import {
  Badge,
  Button,
  Input,
  Stack,
  Text,
  useClipboard,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react'
import { useParams, useLocation } from 'react-router-dom'
import { Loading } from 'components/Loading'
import { InfoPanel } from 'components/InfoPanel'
import { Fragment, useState, useEffect } from 'react'
import { Event, getEvent } from 'api'

interface ParamTypes {
  id: string
}

export function View() {
  const { id } = useParams<ParamTypes>()
  const location = useLocation<any>()
  const sharableLink = `${window.location.origin}/respond/${id}`
  const { hasCopied, onCopy } = useClipboard(sharableLink)
  const [isLoading, setLoading] = useState(true)
  const [event, setEvent] = useState<Event>()

  useEffect(() => {
    async function loadEvent() {
      const result = await getEvent(id)
      setEvent(result)
      setLoading(false)
    }
    loadEvent()
  }, [id])

  return (
    <Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <Stack spacing={{ base: '4', md: '5' }} flex="1">
          {location.state && location.state.firstView ? (
            <InfoPanel
              backgroundColor="green.50"
              color="green.700"
              icon={<Text>👏</Text>}
              text={`Nice work ${event!.author}! Your event has been created.`}
              fontSize="md"
            />
          ) : (
            <InfoPanel
              backgroundColor="gray.50"
              color="gray.700"
              icon={<Text>👋</Text>}
              text={`Welcome back ${event!.author}! ${
                event!.responses.length === 0 ? 'None' : event!.responses.length
              } of your friends have responded to your event.`}
              fontSize="md"
            />
          )}
          <Stack spacing="1">
            <Text>Here is the link to send your friends:</Text>
            <Input value={sharableLink} isReadOnly />
            <Button onClick={onCopy}>{hasCopied ? 'Copied!' : 'Copy to clipboard'}</Button>
          </Stack>
          <Text>Keep this page open and responses will appear below.</Text>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Dates Available</Th>
              </Tr>
            </Thead>
            <Tbody>
              {event!.responses.map((response) => {
                return (
                  <Tr>
                    <Td>{response.author}</Td>
                    <Td>
                      <Stack spacing="1">
                        {response.formattedDates!.map((formattedDate) => {
                          const formattedDateString = formattedDate.date.toLocaleDateString(
                            undefined,
                            {
                              weekday: 'short',
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                            }
                          )
                          return (
                            <div>
                              <Badge
                                variant="subtle"
                                fontSize="sm"
                                fontWeight="500"
                                colorScheme={formattedDate.suitable ? 'green' : 'gray'}
                              >
                                {formattedDateString}
                              </Badge>
                            </div>
                          )
                        })}
                      </Stack>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </Stack>
      )}
    </Fragment>
  )
}
