import { Box, Center, Spinner } from '@chakra-ui/react'

export function Loading() {
  return (
    <Box flex="1" padding="3">
      <Center>
        <Spinner />
      </Center>
    </Box>
  )
}
