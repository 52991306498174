import React, { useState } from 'react'
import {
  Badge,
  Box,
  Button,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from '@chakra-ui/react'
import { ArrowForwardIcon, QuestionOutlineIcon } from '@chakra-ui/icons'
import { useHistory } from 'react-router-dom'
import { createEvent } from 'api'
import { InfoPanel } from 'components/InfoPanel'

export function Create() {
  const [author, setAuthor] = useState('')
  const [isLoading, setLoading] = useState(false)
  const history = useHistory()

  const createEventSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    setLoading(true)
    try {
      const createEventResult = await createEvent({ author })
      history.push(`/view/${createEventResult.id}`, {
        firstView: true,
      })
    } catch (error) {
      setLoading(false)
      // TODO: Error handling
    }
  }

  return (
    <Box as="form" flex="1" onSubmit={createEventSubmit}>
      <Stack spacing={{ base: '4', md: '5' }}>
        <Heading size="md">Create Event</Heading>
        <InfoPanel
          backgroundColor="gray.50"
          color="gray.500"
          icon={<QuestionOutlineIcon />}
          text="Fill in the details and a link will be created that you can send your friends."
        />
        <Stack>
          <Text>What is your name?</Text>
          <Input
            value={author}
            onChange={(event) => {
              setAuthor(event.target.value)
            }}
            placeholder="Name"
            size="lg"
            fontSize="md"
          />
        </Stack>
        <Stack marginBottom="1">
          <Text>What event are you organising?</Text>
          <InputGroup justifyContent="center">
            <Input
              placeholder="Event name, e.g. Birthday"
              size="lg"
              fontSize="md"
              paddingRight="6rem"
            />
            <InputRightElement width="6rem" height="100%">
              <Badge opacity="0.5">OPTIONAL</Badge>
            </InputRightElement>
          </InputGroup>
        </Stack>
        <Stack paddingTop="2" direction={{ base: 'column-reverse', md: 'row' }}>
          <Button
            colorScheme="gray"
            textColor="gray.500"
            size="lg"
            fontSize="md"
            isFullWidth
            onClick={() => {
              history.push('/')
            }}
          >
            Back
          </Button>
          <Button
            colorScheme="blue"
            fontSize="md"
            isDisabled={!author}
            isLoading={isLoading}
            loadingText="Creating event..."
            rightIcon={<ArrowForwardIcon />}
            size="lg"
            type="submit"
            isFullWidth
          >
            Create event
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}
