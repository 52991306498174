const API_PATH = `${process.env.REACT_APP_API_PATH}`

export type Event = {
  id: string
  author: string
  name?: string
  startDate?: Date
  endDate?: Date
  responses: Response[]
}

type CreateEventParams = Omit<Event, 'id' | 'responses'>

type CreateEventResponse = {
  ok: boolean
  id: string
}

export type Response = {
  author: string
  dates: string[]
  formattedDates?: ResponseDate[]
}

export type ResponseDate = {
  dayString: string
  dateString: string
  date: Date
  suitable: boolean
}

type CreateResponseResponse = {
  ok: boolean
  id: string
}

export async function createEvent(params: CreateEventParams): Promise<CreateEventResponse> {
  const response = await fetch(`${API_PATH}/events`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.json()
}

export async function getEvent(id: string): Promise<Event> {
  const response = await fetch(`${API_PATH}/events/${id}`)
  const event = (await response.json()) as Event

  // Collect suitable dates.
  const suitableDates: Record<string, number> = {}

  // Parse dates.
  event.responses.forEach((response) => {
    response.formattedDates = response.dates.map((dateString) => {
      const dayString = dateString.substr(0, 10)
      const dateComponents = dayString.split('-')
      const date = new Date()
      date.setFullYear(
        parseInt(dateComponents[0]),
        parseInt(dateComponents[1]) - 1,
        parseInt(dateComponents[2])
      )

      if (!suitableDates[dayString]) {
        suitableDates[dayString] = 1
      } else {
        suitableDates[dayString]++
      }

      return {
        dayString,
        dateString,
        date,
        suitable: false,
      }
    })
  })

  // Search for suitable dates. These are days that have the exact amount of responses.
  event.responses.forEach((response) => {
    response.formattedDates!.forEach((formattedDate) => {
      if (suitableDates[formattedDate.dayString] === event.responses.length) {
        formattedDate.suitable = true
      }
    })
  })

  return event
}

export async function createResponse(
  eventId: string,
  params: Response
): Promise<CreateResponseResponse> {
  const response = await fetch(`${API_PATH}/events/${eventId}/responses`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.json()
}
